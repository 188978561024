import React from "react";
import {graphql, useStaticQuery} from "gatsby";
import {GatsbyImage} from "gatsby-plugin-image";
import style from "./partnerBanner.module.css";

export const PartnerBanner = () => {
    const data = useStaticQuery(graphql`
    query {
        sunrise: file(relativePath: { eq: "enterprise/logos/01Sunrise.jpg" }) {
        childImageSharp {
          gatsbyImageData(
            width: 40
            height: 40
            formats: [WEBP]
            layout: FIXED
            placeholder: NONE
          )
        }
      }
      
      partnerBraut: file(relativePath: { eq: "lan-partner-braut.jpg" }) {
        childImageSharp {
          gatsbyImageData(
            width: 40
            height: 40
            formats: [WEBP]
            layout: FIXED
            placeholder: NONE
          )
        }
      }
      partnerFestzeit: file(relativePath: { eq: "lan-partner-festzeit.jpg" }) {
        childImageSharp {
          gatsbyImageData(
            width: 40
            height: 40
            formats: [WEBP]
            layout: FIXED
            placeholder: NONE
          )
        }
      }
      partnerHochzeitsmesse: file(
        relativePath: { eq: "lan-partner-hochzeitsmesse.jpg" }
      ) {
        childImageSharp {
          gatsbyImageData(
            width: 40
            height: 40
            formats: [WEBP]
            layout: FIXED
            placeholder: NONE
          )
        }
      }
      partnerKurz: file(relativePath: { eq: "lan-partner-kurz.jpg" }) {
        childImageSharp {
          gatsbyImageData(
            width: 40
            height: 40
            formats: [WEBP]
            layout: FIXED
            placeholder: NONE
          )
        }
      }
      partnerZankyou: file(relativePath: { eq: "lan-partner-zankyou.jpg" }) {
        childImageSharp {
          gatsbyImageData(
            width: 40
            height: 40
            formats: [WEBP]
            layout: FIXED
            placeholder: NONE
          )
        }
      }
      partnerZurich: file(relativePath: { eq: "lan-partner-zurich.jpg" }) {
        childImageSharp {
          gatsbyImageData(
            width: 40
            height: 40
            formats: [WEBP]
            layout: FIXED
            placeholder: NONE
          )
        }
      }
      partnerMeister: file(relativePath: { eq: "lan-partner-meister.jpg" }) {
        childImageSharp {
          gatsbyImageData(
            width: 40
            height: 40
            formats: [WEBP]
            layout: FIXED
            placeholder: NONE
          )
        }
      }
      partnerSwica: file(relativePath: { eq: "lan-partner-swica.jpg" }) {
        childImageSharp {
          gatsbyImageData(
            width: 40
            height: 40
            formats: [WEBP]
            layout: FIXED
            placeholder: NONE
          )
        }
      } 
      
      
      partnerAxa: file(relativePath: { eq: "enterprise/logos/10AXA.jpg" }) {
        childImageSharp {
          gatsbyImageData(
            width: 40
            height: 40
            formats: [WEBP]
            layout: FIXED
            placeholder: NONE
          )
        }
      }
      partnerCloudbrew: file(
        relativePath: { eq: "enterprise/logos/11Cloudbrew.jpg" }
      ) {
        childImageSharp {
          gatsbyImageData(
            width: 40
            height: 40
            formats: [WEBP]
            layout: FIXED
            placeholder: NONE
          )
        }
      }
    }
  `);

    return (
        <div className={style.partnerBanner}>
            <div className={style.imageContainer}>
                <GatsbyImage
                    alt="Migros"
                    image={data["sunrise"].childImageSharp.gatsbyImageData}
                />
            </div>
            <div className={style.imageContainer}>
                <GatsbyImage
                    alt="Migros"
                    image={data["partnerHochzeitsmesse"].childImageSharp.gatsbyImageData}
                />
            </div>

            <div className={`${style.imageContainer} ${style.hideOnMobile}`}>
                <GatsbyImage
                    alt="Festzeit"
                    image={data["partnerFestzeit"].childImageSharp.gatsbyImageData}
                />
            </div>
            <div className={`${style.imageContainer} ${style.hideOnMobile}`}>
                <GatsbyImage
                    alt="Braut"
                    image={data["partnerBraut"].childImageSharp.gatsbyImageData}
                />
            </div>
            <div className={`${style.imageContainer} ${style.hideOnMobile}`}>
                <GatsbyImage
                    alt="Kurz"
                    image={data["partnerKurz"].childImageSharp.gatsbyImageData}
                />
            </div>
            <div className={style.imageContainer}>
                <GatsbyImage
                    alt="Zankyou"
                    image={data["partnerZankyou"].childImageSharp.gatsbyImageData}
                />
            </div>
            <div className={style.imageContainer}>
                <GatsbyImage
                    alt="Zurich"
                    image={data["partnerZurich"].childImageSharp.gatsbyImageData}
                />
            </div>
            <div className={`${style.imageContainer} ${style.hideOnMobile}`}>
                <GatsbyImage
                    alt="Swica"
                    image={data["partnerSwica"].childImageSharp.gatsbyImageData}
                />
            </div>
            <div className={`${style.imageContainer} ${style.hideOnMobile}`}>
                <GatsbyImage
                    alt="Meister"
                    image={data["partnerMeister"].childImageSharp.gatsbyImageData}
                />
            </div>
            <div className={`${style.imageContainer} ${style.hideOnMobile}`}>
                <GatsbyImage
                    alt="Axa"
                    image={data["partnerAxa"].childImageSharp.gatsbyImageData}
                />
            </div>
            <div className={`${style.imageContainer} ${style.hideOnMobile}`}>
                <GatsbyImage
                    alt="Cloudbrew"
                    image={data["partnerCloudbrew"].childImageSharp.gatsbyImageData}
                />
            </div>
        </div>
    );
};
