import style from "./enterpriseTiles.module.css";
import React from "react";
import {GatsbyImage} from "gatsby-plugin-image";
import {SsyLink} from "../ssyLink/ssyLink";

export const EnterpriseTextTile = ({rational, title, body, intl, index, button, childContent}) => (
    <div className={`grid-item ${style.textTile}`}>
        <h3 className={`${(index % 2 ===0)?style.textTileYellow : style.textTileBlue}`}>{intl.formatMessage({id: rational})}</h3>
        <h2>{intl.formatMessage({id: title})}</h2>
        {childContent && <div className={style.childContent}>
            {childContent}
        </div>}
        {!!body && <p>{intl.formatMessage({id: body})}</p>}
        {button && <SsyLink type="primary" id={button.id} internal={false} to={button.link} label={intl.formatMessage({id: button.text})} />}
    </div>
)

export const EnterpriseImageTile = ({image, childContent, index}) => (
    <div className={`grid-item ${style.imageTile} ${(index % 2 ===0)? style.imageTileYellow :style.imageTileBlue}`}>
        <div>
            <GatsbyImage
                style={{ borderRadius: '2%' }}
                image={image}
                alt="phone"/>
        </div>
        {childContent && <div className={style.childContent}>
            {childContent}
        </div>}

    </div>
)