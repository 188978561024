import style from "./imageWithTitleAndBody.module.css";
import React from "react";
import {GatsbyImage} from "gatsby-plugin-image";

export const ImageWithTitleAndBody = ({title, header, body, image, intl}) => (
    <article className={style.imageWithTitleAndBody}>
        <GatsbyImage
            alt={intl.formatMessage({id: title})}
            image={image}
        />
        <h3 className={style.highlighted}>{intl.formatMessage({id: title})}</h3>
        <h3 className={style.header}>{intl.formatMessage({id: header})}</h3>
        <p>{intl.formatMessage({id: body})}</p>
    </article>
)