import {GatsbyImage} from "gatsby-plugin-image";
import React from "react";
import style from "./teamList.module.css";
import {graphql, useStaticQuery} from "gatsby";


export const TeamList = () => {

    const data = useStaticQuery(graphql`
    query {
      teamMember1: file(relativePath: { eq: "enterprise/team/01sam.png" }) {
        childImageSharp {
          gatsbyImageData(
            formats: [WEBP]
             height: 120
            layout: CONSTRAINED
            placeholder: BLURRED
          )
        }
      }
      
      teamMember2: file(relativePath: { eq: "enterprise/team/02simi.png" }) {
        childImageSharp {
          gatsbyImageData(
            formats: [WEBP]
             height: 120
            layout: CONSTRAINED
            placeholder: BLURRED
          )
        }
      }
      
      teamMember3: file(relativePath: { eq: "enterprise/team/03fabio.png" }) {
        childImageSharp {
          gatsbyImageData(
            formats: [WEBP]
             height: 120
            layout: CONSTRAINED
            placeholder: BLURRED
          )
        }
      }
      
      teamMember4: file(relativePath: { eq: "enterprise/team/04josh.png" }) {
        childImageSharp {
          gatsbyImageData(
            formats: [WEBP]
            height: 120
            layout: CONSTRAINED
            placeholder: BLURRED
          )
        }
      }
      
      teamMember5: file(relativePath: { eq: "enterprise/team/05dani.png" }) {
        childImageSharp {
          gatsbyImageData(
            formats: [WEBP]
            height: 120
            layout: CONSTRAINED
            placeholder: BLURRED
          )
        }
      }
      
      teamMember6: file(relativePath: { eq: "enterprise/team/06ivan.png" }) {
        childImageSharp {
          gatsbyImageData(
            formats: [WEBP]
            height: 120
            layout: CONSTRAINED
            placeholder: BLURRED
          )
        }
      }
      
        
    }
    `);
    
    return (

        <div className={style.teamList}>
            <div className={style.teamListItem}>
                <GatsbyImage
                    alt="Ivan"
                    image={data["teamMember6"].childImageSharp.gatsbyImageData}
                />
                <h3>Ivan</h3>
            </div>
            <div className={style.teamListItem}>
                <GatsbyImage
                    alt="Simi"
                    image={data["teamMember2"].childImageSharp.gatsbyImageData}
                />
                <h3>Simi</h3>
            </div>
            <div className={style.teamListItem}>
                <GatsbyImage
                    alt="Fabio"
                    image={data["teamMember3"].childImageSharp.gatsbyImageData}
                />
                <h3>Fabio</h3>
            </div>
            <div className={style.teamListItem}>
                <GatsbyImage
                    alt="Josh"
                    image={data["teamMember4"].childImageSharp.gatsbyImageData}
                />
                <h3>Josh</h3>
            </div>
            <div className={style.teamListItem}>
                <GatsbyImage
                    alt="Dani"
                    image={data["teamMember5"].childImageSharp.gatsbyImageData}
                />
                <h3>Dani</h3>
            </div>
            <div className={style.teamListItem}>
                <GatsbyImage
                    alt="Sam"
                    image={data["teamMember1"].childImageSharp.gatsbyImageData}
                />
                <h3>Sam</h3>
            </div>
        </div>)
}