import React from "react";
import Seo from "../components/seo/seo";
import {useIntl} from "gatsby-plugin-intl";
import {Layout} from "../components/layout/layout";
import style from "../assets/css/enterprise.module.css";
import {PartnerBanner} from "../components/partnerBanner/partnerBanner";
import {SsyIntersectionObserver} from "../components/ssyIntersectionObserver/ssyIntersectionObserver";
import {SsyLink} from "../components/ssyLink/ssyLink";
import {RaisingBox} from "../components/raisingBox/raisingBox";
import {HeaderType} from "../components/header/header";
import {graphql, useStaticQuery} from "gatsby";
import {GatsbyImage} from "gatsby-plugin-image";
import {ImageWithTitleAndBody} from "../components/imageWithTitleAndBody/imageWithTitleAndBody";
import {EnterpriseImageTile, EnterpriseTextTile} from "../components/enterpriseTile/enterpriseTiles";
import {NewSlider} from "../components/newSlider/newSlider";
import checkMark from "../assets/images/enterprise/others/check.png";
import {useBreakpoint} from "gatsby-plugin-breakpoints";
import {TeamList} from "../components/teamList/teamList";

const EnterprisePage = (props) => {
    const data = useStaticQuery(graphql`
    query {
      header: file(relativePath: { eq: "enterprise/Header-iPhone-14.png" }) {
        childImageSharp {
          gatsbyImageData(
            formats: [WEBP]
            layout: CONSTRAINED
            height: 700
            width: 700
            placeholder: BLURRED
            blurredOptions: { width: 400 }
            quality: 80
          )
        }
      }
      step1: file(relativePath: { eq: "enterprise/phones/Phone00.png" }) {
        childImageSharp {
          gatsbyImageData(
            formats: [WEBP]
            layout: CONSTRAINED
            height: 200
            placeholder: BLURRED
          )
        }
      }
      step2: file(relativePath: { eq: "enterprise/phones/Phone02.png" }) {
        childImageSharp {
          gatsbyImageData(
            formats: [WEBP]
             height: 200
           layout: CONSTRAINED
            placeholder: BLURRED
          )
        }
      }
      step3: file(relativePath: { eq: "enterprise/phones/Phone03.png" }) {
        childImageSharp {
          gatsbyImageData(
            formats: [WEBP]
             height: 200
            layout: CONSTRAINED
            placeholder: BLURRED
          )
        }
      }
      
      teamMember1: file(relativePath: { eq: "enterprise/team/01sam.png" }) {
        childImageSharp {
          gatsbyImageData(
            formats: [WEBP]
             height: 120
            layout: CONSTRAINED
            placeholder: BLURRED
          )
        }
      }
      
      teamMember2: file(relativePath: { eq: "enterprise/team/02simi.png" }) {
        childImageSharp {
          gatsbyImageData(
            formats: [WEBP]
             height: 120
            layout: CONSTRAINED
            placeholder: BLURRED
          )
        }
      }
      
      teamMember3: file(relativePath: { eq: "enterprise/team/03fabio.png" }) {
        childImageSharp {
          gatsbyImageData(
            formats: [WEBP]
             height: 120
            layout: CONSTRAINED
            placeholder: BLURRED
          )
        }
      }
      
      teamMember4: file(relativePath: { eq: "enterprise/team/04josh.png" }) {
        childImageSharp {
          gatsbyImageData(
            formats: [WEBP]
            height: 120
            layout: CONSTRAINED
            placeholder: BLURRED
          )
        }
      }
      
      teamMember5: file(relativePath: { eq: "enterprise/team/05dani.png" }) {
        childImageSharp {
          gatsbyImageData(
            formats: [WEBP]
            height: 120
            layout: CONSTRAINED
            placeholder: BLURRED
          )
        }
      }
      
      teamMember6: file(relativePath: { eq: "enterprise/team/06ivan.png" }) {
        childImageSharp {
          gatsbyImageData(
            formats: [WEBP]
            height: 120
            layout: CONSTRAINED
            placeholder: BLURRED
          )
        }
      }
      
        phone4: file(relativePath: { eq: "enterprise/phones/Phone04.png" }) {
            childImageSharp {
            gatsbyImageData(
                formats: [WEBP]
                height: 350
                layout: CONSTRAINED
                placeholder: BLURRED
            )
            }
        }
        
        phone5: file(relativePath: { eq: "enterprise/phones/Phone05.png" }) {
            childImageSharp {
            gatsbyImageData(
                formats: [WEBP]
                height: 350
                layout: CONSTRAINED
                placeholder: BLURRED
            )
            }
        }
        
        phone6: file(relativePath: { eq: "enterprise/phones/Phone06.png" }) {
            childImageSharp {
            gatsbyImageData(
                formats: [WEBP]
                height: 350
                layout: CONSTRAINED
                placeholder: BLURRED
            )
            }
        }
    }
    `);
    const intl = useIntl();
    const breakpoints = useBreakpoint();


    const headerImage = <div className={style.headerImage}>
        <GatsbyImage
            alt="Banner showing the customized Sam App"
            image={data["header"].childImageSharp.gatsbyImageData}
        />
    </div>;

    const effortlessTitle = <EnterpriseTextTile intl={intl} index={0}
                                                rational="ENTERPRISE_EFFORTLESS_RATIONAL"
                                                title="ENTERPRISE_EFFORTLESS_TITLE"
                                                body="ENTERPRISE_EFFORTLESS_BODY"
                                                button={{
                                                    id: "ENTERPRISE_LEARN_MORE",
                                                    text: "ENTERPRISE_LEARN_MORE",
                                                    link: `${intl.formatMessage({id: "ENTERPRISE_CONTACT_US_LINK"})}`
                                                }}
    />;

    const effortlessImage = <EnterpriseImageTile image={data["phone5"].childImageSharp.gatsbyImageData}
                                                 index={0}
                                                 childContent={<div style={{
                                                     display: "flex",
                                                     flexDirection: "column",
                                                     alignItems: "start"
                                                 }}>
                                                     <h4 style={{margin: 5}}>{intl.formatMessage({id: "ENTERPRISE_PERFECT_FOR"})}</h4>
                                                     <p style={{margin: 5, textAlign: "left"}}><img width="15" height="15"
                                                                                 style={{marginRight: 5}}
                                                                                 src={checkMark}
                                                                                 alt="check mark"/>{intl.formatMessage({id: "ENTERPRISE_CORPORATE_EVENTS"})}
                                                     </p>
                                                     <p style={{margin: 5, textAlign: "left"}}><img width="15" height="15"
                                                                                 style={{marginRight: 5}}
                                                                                 src={checkMark}
                                                                                 alt="check mark"/>{intl.formatMessage({id: "ENTERPRISE_INCENTIVE_TRAVELS"})}
                                                     </p>
                                                     <p style={{margin: 5, textAlign: "left"}}><img width="15" height="15"
                                                                                 style={{marginRight: 5}}
                                                                                 src={checkMark}
                                                                                 alt="check mark"/>{intl.formatMessage({id: "ENTERPRISE_SPONSORING"})}
                                                     </p>
                                                     <p style={{margin: 5, textAlign: "left"}}><img width="15" height="15"
                                                                                 style={{marginRight: 5}}
                                                                                 src={checkMark}
                                                                                 alt="check mark"/>{intl.formatMessage({id: "ENTERPRISE_LOYALTY_PROGRAMS"})}
                                                     </p>
                                                     <p style={{margin: 5, textAlign: "left"}}><img width="15" height="15"
                                                                                 style={{marginRight: 5}}
                                                                                 src={checkMark}
                                                                                 alt="check mark"/>{intl.formatMessage({id: "ENTERPRISE_CONTESTS"})}
                                                     </p>
                                                     <p style={{margin: 5, textAlign: "left"}}><img width="15" height="15"
                                                                                 style={{marginRight: 5}}
                                                                                 src={checkMark}
                                                                                 alt="check mark"/>{intl.formatMessage({id: "ENTERPRISE_CONFERENCES"})}
                                                     </p>
                                                 </div>}

    />;
    const customizableImage = <EnterpriseImageTile image={data["phone4"].childImageSharp.gatsbyImageData}
                                                   index={1}/>;
    const customizableText = <EnterpriseTextTile intl={intl} index={1}
                                                 rational="ENTERPRISE_CUSTOMISABLE_RATIONAL"
                                                 title="ENTERPRISE_CUSTOMISABLE_TITLE"
                                                 body="ENTERPRISE_CUSTOMISABLE_BODY"
                                                 button={{
                                                     id: "ENTERPRISE_GET_IN_TOUCH",
                                                     text: "ENTERPRISE_GET_IN_TOUCH",
                                                     link: `${intl.formatMessage({id: "ENTERPRISE_CONTACT_US_LINK"})}`
                                                 }}
    />;
    const resultOrientedText = <EnterpriseTextTile intl={intl} index={2}
                                                   rational="ENTERPRISE_RESULTS_ORIENTED_RATIONAL"
                                                   title="ENTERPRISE_RESULTS_ORIENTED_TITLE"
                                                   body="ENTERPRISE_RESULTS_ORIENTED_BODY"
                                                   button={{
                                                       id: "ENTERPRISE_SHOW_ME_PROOF",
                                                       text: "ENTERPRISE_SHOW_ME_PROOF",
                                                       link: `${intl.formatMessage({id: "ENTERPRISE_CONTACT_US_LINK"})}`
                                                   }}
    />;
    const resultOrientedImage = <EnterpriseImageTile image={data["phone6"].childImageSharp.gatsbyImageData}
                                                     index={2}/>;
    return (
        <Layout headerType={HeaderType.enterprise}>
            <Seo
                title={intl.formatMessage({id: "ENTERPRISE_META_TITLE"})}
                url={`${props.location.pathname}/enterprise`}
                lang={intl.formatMessage({id: "MAIN_LANGUAGE"})}
                description={intl.formatMessage({id: "ENTERPRISE_META_DESCRIPTION"})}
            />
            <main className={style.pageStyles}>
                <div className={style.firstPage}>
                    <SsyIntersectionObserver>
                        <RaisingBox>
                            <div className={style.headerSection}>
                                {breakpoints.sm ?
                                    headerImage
                                    : <></>}
                                <section className={`${style.firstSection} ${style.section}`}>
                                    <h1>{intl.formatMessage({id: "ENTERPRISE_TITLE"})}</h1>
                                    <h3 className={style.subtitle}>{intl.formatMessage({id: "ENTERPRISE_MESSAGE"})}</h3>
                                    <SsyLink
                                        type="primary"
                                        id="ENTERPRISE_BOOK_DEMO"
                                        to={`${intl.formatMessage({id: "ENTERPRISE_CONTACT_US_LINK"})}`}
                                        internal={false}
                                        label={intl.formatMessage({id: "ENTERPRISE_BOOK_DEMO"})}
                                    />
                                </section>
                                {!breakpoints.sm ?
                                    headerImage
                                    : <></>}
                            </div>
                        </RaisingBox>
                    </SsyIntersectionObserver>
                    <SsyIntersectionObserver>
                        <RaisingBox>
                            <section className={`${style.section} ${style.collabs}`}>
                                <h3 className={style.subtitle}>
                                    {intl.formatMessage({id: "ENTERPRISE_COLLABORATION_TITLE"})}
                                </h3>
                                <PartnerBanner/>
                            </section>
                        </RaisingBox>
                    </SsyIntersectionObserver>
                </div>

                {/* benefits */}

                <div>
                    <SsyIntersectionObserver>
                        <RaisingBox>
                            <section className={style.section}>
                                
                                    {breakpoints.sm ?
                                        <div className={style.gridReason}>
                                            {effortlessTitle}
                                            {effortlessImage}
                                            {customizableText}
                                            {customizableImage}
                                            {resultOrientedText}
                                            {resultOrientedImage}
                                        </div>
                                        :
                                        <div className={style.gridReason}>
                                            {effortlessTitle}
                                            {effortlessImage}
                                            {customizableImage}
                                            {customizableText}
                                            {resultOrientedText}
                                            {resultOrientedImage}
                                        </div>
                                    }

                            </section>
                        </RaisingBox>
                    </SsyIntersectionObserver>
                </div>

                {/* steps */}

                <SsyIntersectionObserver>
                    <RaisingBox>
                        <section className={style.section}>
                            <h2>{intl.formatMessage({id: "ENTERPRISE_STEPS_TITLE"})}</h2>
                            <ol className={style.stepList}>
                                <li>
                                    <ImageWithTitleAndBody
                                        image={data["step1"].childImageSharp.gatsbyImageData}
                                        title="ENTERPRISE_STEPS_1_TITLE"
                                        header="ENTERPRISE_STEPS_1_HEADER"
                                        body="ENTERPRISE_STEPS_1_BODY"
                                        intl={intl}/>
                                </li>
                                <li>
                                    <ImageWithTitleAndBody
                                        image={data["step2"].childImageSharp.gatsbyImageData}
                                        title="ENTERPRISE_STEPS_2_TITLE"
                                        header="ENTERPRISE_STEPS_2_HEADER"
                                        body="ENTERPRISE_STEPS_2_BODY"
                                        intl={intl}/>
                                </li>
                                <li>
                                    <ImageWithTitleAndBody
                                        image={data["step3"].childImageSharp.gatsbyImageData}
                                        title="ENTERPRISE_STEPS_3_TITLE"
                                        header="ENTERPRISE_STEPS_3_HEADER"
                                        body="ENTERPRISE_STEPS_3_BODY"
                                        intl={intl}/>
                                </li>
                            </ol>
                        </section>
                    </RaisingBox>
                </SsyIntersectionObserver>

                <SsyIntersectionObserver>
                    <RaisingBox>
                        <section className={style.section}>
                            <h1>{intl.formatMessage({id: "ENTERPRISE_BEHIND_THE_SCENES"})}</h1>
                            <p className={style.secondaryText}>{intl.formatMessage({id: "ENTERPRISE_BEHIND_THE_SCENES_BODY"})}</p>
                            <TeamList />

                            <SsyLink
                                type="primary"
                                id="ENTERPRISE_GET_IN_TOUCH"
                                to={`${intl.formatMessage({id: "ENTERPRISE_CONTACT_US_LINK"})}`}
                                internal={false}
                                label={intl.formatMessage({id: "ENTERPRISE_GET_IN_TOUCH"})}
                            />
                        </section>
                    </RaisingBox>
                </SsyIntersectionObserver>

                <SsyIntersectionObserver>
                    <RaisingBox>
                        <section
                            className={`${style.forthSection} ${style.section} commentsSection`}
                        >
                            <h2 className={style.titleForth}>
                                {intl.formatMessage({id: "LANDING_COSTUMERS_TITLE"})}
                            </h2>
                            <p className={style.subtitleForth}>
                                {intl.formatMessage({id: "LANDING_COSTUMERS_BODY"})}
                            </p>
                            <NewSlider
                                translationPrefix="LANDING_TESTIMONIAL"
                                intl={intl}
                                data={[1, 2, 3, 4, 2]}
                            />
                            <NewSlider
                                translationPrefix="LANDING_TESTIMONIAL"
                                intl={intl}
                                delayed={true}
                                data={[5, 6, 7, 8, 9]}
                            />
                        </section>
                    </RaisingBox>
                </SsyIntersectionObserver>
            </main>
        </Layout>
    );
};

export default EnterprisePage;
